import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Splash from './splash';
import { AppProvider } from './AppState';


const root = ReactDOM.createRoot(document.getElementById('root'));

const LazyRouters = React.lazy(() => import('./routers'));

root.render(
  // <React.StrictMode>
    <Suspense fallback={<Splash />}>
      <AppProvider>
        <LazyRouters />
      </AppProvider>
    </Suspense>
  // </React.StrictMode>
);