// AppState.js
import React, { createContext, useContext, useRef, useState } from 'react';

const AppContext = createContext();

export const AppProvider = ({ children }) => {
    const viewerRef = useRef(null);
    const viewportRef = useRef(null);
    const moveableRef = useRef(null);
    const canvasRef = useRef();

    const [position, setPosition] = useState({ x: 20, y: 20 });
    const [history, setHistory] = useState([{ x: 20, y: 20 }]);
    const [historyStep, setHistoryStep] = useState(0);
    
    const [deviceSize, setDeviceSize] = useState('100%');
    const [verticalGuidelines, setVerticalGuidelines] = useState([0]);
    const [horizontalGuidelines, setHorizontalGuidelines] = useState([0]);
    const [activeTool, setActiveTool] = useState('Templates');
    const [targets, setTargets] = useState([]);
    const [undoStack, setUndoStack] = useState([]);
    const [redoStack, setRedoStack] = useState([]);
    const [elementGuidelines, setElementGuidelines] = useState([]);
    
    
    const [editable, setEditable] = useState(true); 

    return (
        <AppContext.Provider value={{
            deviceSize, setDeviceSize, viewerRef, viewportRef,
            horizontalGuidelines, setHorizontalGuidelines,
            verticalGuidelines, setVerticalGuidelines,
            activeTool, setActiveTool, moveableRef,
            targets, setTargets, undoStack, setUndoStack,
            redoStack, setRedoStack, canvasRef,
            position, setPosition, history, setHistory,
            historyStep, setHistoryStep, elementGuidelines, setElementGuidelines,
            editable, setEditable
        }}>
            {children}
        </AppContext.Provider>
    );
};

export const useAppContext = () => {
    return useContext(AppContext);
};
